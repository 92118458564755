<template>
  <b-container>
    <Profile />
  </b-container>
</template>

<script>
import Profile from "../components/profile/Profile";
export default {
  components: {
    Profile
  }
};
</script>

<style></style>
